<template>
<form  class="">
   <div class="row g-3"> 
      <div class="col-lg-7">
         <div class="card">
            <div class="card-body">
               <div class="row g-3">
                  <div class="col-12">
                     <label class="form-label">Retailer location</label>
                     <select v-model="data.store_id" name="store_id"
                     class="form-select" :class="{ 'is-invalid': v$.data.store_id.$error  }">
                        <option value="" disabled selected>--Select Store--</option>
                        <option  v-for="(store, tIndex) in storeList"
                           :value="store.id" :key="tIndex" > {{store.title}} </option>
                     </select>
                     <div v-for="(item, index) in v$.data.store_id.$errors"
                        :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                     </div> 
                  </div>
                  <div class="col-12">
                     <label class="form-label ">Payment Frequency</label>
                     <select v-model="data.renewal_frequency" name="renewal_frequency"
                     class="form-select" :class="{ 'is-invalid': v$.data.renewal_frequency.$error  }">
                        <option value="" disabled selected>--Select frequency--</option>
                        <option  v-for="(frequency, tIndex) in paymentFrequencies"
                           :value="frequency.value" :key="tIndex" > {{frequency.label}} </option>
                     </select>
                     <div v-for="(item, index) in v$.data.renewal_frequency.$errors"
                        :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                     </div>
                  </div>
                  <div class="col-12">
                     <label class="form-label" for="leased_amount">Lease Amount</label>
                     <div class="input-group">
                        <span class="input-group-text">$</span>
                        <input @input="calculateInitalAmount()" v-model="data.leased_amount" id="leased_amount" class="form-control" type="number"
                        placeholder="Lease amount" :class="{ 'is-invalid': v$.data.leased_amount.$error }" >
                        <span class="input-group-text">.00</span>
                     </div>
                     <div v-for="(item, index) in v$.data.leased_amount.$errors"
                        :key="index" class="text-danger">
                        <span v-if="item.$message">{{ item.$message }}</span>
                     </div> 
                  </div>
                  <div class="col-md-6">
                     <label for="pricing_factor">
                        Pricing Factor <span class="text-danger small">*</span>
                     </label>
                     <input type="number" v-model="data.pricing_factor"
                        :class="{ 'is-invalid': v$.data.pricing_factor.$error }"
                        id="pricing_factor" name="pricing_factor" class="form-control"
                        placeholder="Enter pricing factor" />
                     <div v-for="(item, index) in v$.data.pricing_factor.$errors" :key="index"
                        class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                     </div>
                  </div>
                  <div class="col-md-6">
                     <label class="form-label" for="funding_option">Funding Option</label>
                     <select v-model="data.funding_option" name="funding_option"
                        class="form-select" :class="{ 'is-invalid': v$.data.funding_option.$error }">
                        <option value="" disabled selected>--Select term--</option>
                        <option  v-for="(fund_option, fOIndex) in fundingOptions"
                           :value="fund_option.value" :key="fOIndex" > {{fund_option.label}} </option>
                     </select>
                     <div v-for="(item, index) in v$.data.funding_option.$errors"
                        :key="index" class="text-danger">
                        <span v-if="item.$message">{{ item.$message }}</span>
                     </div> 
                  </div>
                  <div class="col-md-6">
                     <label class="form-label ">Lease Term</label>
                     <select v-model="data.contract_term" name="contract_term"
                     class="form-select">
                        <option value="" disabled selected>--Select term--</option>
                        <option  v-for="(frequency, tIndex) in leaseTerms"
                           :value="frequency.value" :key="tIndex" > {{frequency.label}} </option>
                     </select>
                     <div v-for="(item, index) in v$.data.contract_term.$errors"
                        :key="index" class="text-danger">
                        <span v-if="item.$message">{{ item.$message }}</span>
                     </div> 
                  </div>
                  <div class="col-md-6">
                     <label for="initial_payment">
                       Down Payment (min: 10%) <span class="text-danger small">*</span>
                     </label>
                     <input type="number" v-model="data.initial_payment"
                        :class="{ 'is-invalid': v$.data.initial_payment.$error }"
                        id="initial_payment" name="initial_payment" class="form-control"
                        placeholder="Enter Down Payment" />
                     <div v-for="(item, index) in v$.data.initial_payment.$errors" :key="index"
                        class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                     </div>
                  </div>
                  <div class="col-12" >
                     <label class="form-label" for="description">Item Description</label>
                      <textarea v-model="data.description" class="form-control" rows="4"
                        placeholder="Write description" name="description"></textarea>
                  </div>
               </div>
            </div>
         </div>
         <div class="mb-3">
            <div class="">
               <b-button variant="primary" class="me-3"
                  @click.prevent="calculate()"
                  type="button"> Get Estimate </b-button>
               <b-button variant="outline-dark"  class=""
               @click.prevent="create()"
               type="button">Create Price Tag</b-button>
            </div>
         </div>
      </div>       
      <div class="col-lg-5" >
         <div class="card">
            <div class="card-header" >
               <h5 class="mb-0">Payment Overview</h5>
            </div>
            <div class="card-body">
               <div class="d-flex align-items-center justify-content-between">
                  <span>Lease Amount</span> <strong>{{ numberFormat(data.leased_amount) }}</strong>
               </div>
            </div>
         </div>
         <div class="card loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <div class="card-header">
               <h5 class="mb-0">Recurring Payments</h5>
            </div>
            <div class="card-body">
               <div class="d-flex align-items-center justify-content-between">
                  <span class="me-2"> Renewal Payment Amount</span>  <strong>{{ numberFormat(data.renewal_amount) }}</strong>
               </div>
               <hr />
               <div class="d-flex align-items-center justify-content-between">
                  <span>Total</span> <strong>{{ numberFormat(data.renewal_amount)}}</strong>
               </div>
            </div>
         </div>
         <div class="card loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <div class="card-header" >
               <h5 class="mb-0">Totals</h5>
            </div>
            <div class="card-body">
               <div class="d-flex mb-2 align-items-center justify-content-between">
                  <span>Today's Down Payment</span> <strong>{{numberFormat(data.initial_payment)}}</strong>
               </div>
               <div class="d-flex mb-2 align-items-center justify-content-between">
                  <span>Moriso Cash Price</span> <strong>{{numberFormat(data.moriso_cash_price)}}</strong>
               </div>
               <div v-if="data.contract_term" class="d-flex mb-2 align-items-center justify-content-between">
                  <span>{{data.contract_term}}-Month Total of Payments</span> <strong>{{numberFormat(data.total_amount)}}</strong>
               </div>
               <div class="d-flex mb-2 align-items-center justify-content-between">
                  <span>Payment Frequency</span> <strong>{{getFrequency}}</strong>
               </div>
               <div class="d-flex align-items-center justify-content-between">
                  <span>Number of Payments</span> <strong>{{data.number_of_renewals || '...'}}</strong>
               </div>
            </div>
         </div>
      </div>
   </div>
</form>
</template>

<script>
import { required, minValue, maxValue} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import IsLoading from '@/components/IsLoading.vue';

export default {
  components: { IsLoading },
    setup() {
        return { v$: useVuelidate() };
    },
    data(){
        return{
            isLoading: false,
            data: {
               store_id: "",
               description: "",

               leased_amount: "",
               initial_payment: "",
               pricing_factor: 2.10,
               renewal_frequency: "",
               funding_option: "",
               contract_term: "",
               moriso_cash_price: "",
               number_of_renewals: "",
               renewal_amount: "",
               total_amount: ""
            },
            paymentFrequencies:[
               { value: 'weekly', label: 'Weekly'},
               { value: 'every_other_week', label: 'Every Other Week' },
               { value: 'twice_a_month', label: 'Twice a Month' },
               { value: 'monthly', label: 'Monthly' },
            ],
            leaseTerms:[
               { value: '3', label: '3 months'},
               { value: '4', label: '4 months'},
               { value: '5', label: '5 months'},
               { value: '6', label: '6 months'},
               { value: '7', label: '7 months'},
               { value: '8', label: '8 months'},
               { value: '9', label: '9 months'},
               { value: '10', label: '10 months'},
               { value: '11', label: '11 months'},
               { value: '12', label: '12 months'},
            ],
            fundingOptions:[
               { value: 'cash', label: 'Cash'},
               { value: 'repair', label: 'Repair' },
               { value: 'buy', label: 'Buy' },
            ],
        }
    },
    validations() {
      return {
         data:{
            store_id: { required },
            contract_term: { required },
            pricing_factor: { 
               required,
               minValue: minValue(1.5),
               maxValue: maxValue(5)
            },
            initial_payment: {
               required,
               maxValue: maxValue(parseFloat(this.data.leased_amount))
            },
            funding_option: { required },
            leased_amount: { required },
            renewal_frequency: { required },
         }
      }
    },
    computed:{
      getFrequency(){
         if(!this.data.renewal_frequency) return "..."
         const item = this.paymentFrequencies.find((i) => i.value == this.data.renewal_frequency)
         return item.label
      },
      numberFormat(){
         return (value) => value ? `$${this.$filters.money_format(value)}` : '...'
      },
      storeList(){
         return this.$store.state.store.list
      },
    },
    methods:{
      create() {
         this.v$.$validate().then(result =>{
            if (!result) {this.alertError('Form is not valid'); return;}
            if (!this.data.description) {this.alertError('The description is required'); return;}
            
            const formData = new FormData()
            formData.append('data', JSON.stringify(this.data))
            const payload = {
               url: '/contracts/price-tags',
               data: formData
            }
            this.$store.dispatch("changeLoaderValue", true)
            this.serverReceipt(payload)
                .then((response) => {
                this.$store.dispatch("changeLoaderValue", false)
                if(response.status != 200){
                    this.alertError('Could not generate receipt');
                }
            })
         })
      },
      calculate(){
         this.v$.$validate().then(result =>{
            if (!result) {this.alertError('Form is not valid'); return;}
            const formData = new FormData()
            formData.append('data', JSON.stringify(this.data))
            this.isLoading = true
            this.$http.post('/contracts/estimate', formData)
            .then((response) => {
               this.isLoading = false
               if(response.data.success){
                  Object.assign(this.data, response.data.data)
               }
            })
         })
      },
      calculateInitalAmount(){
         this.data.initial_payment = 0.1 * this.data.leased_amount
      },
      resetForm(){
         this.v$.$reset()
         this.$emit("closeMe")
      }
    },
}
</script>