<template>
  <div class="row mb-5 gy-2">
    <div class="col-12">
      <h5 class="mb-3">Payment calculator</h5>
      <div>
        <lease-calculator-form @closeMe="afterCreated()" />
      </div>
    </div>
  </div>
</template>

<script>
import LeaseCalculatorForm from '@/components/forms/LeaseCalculatorForm.vue'

export default {
  components: { LeaseCalculatorForm },
  methods:{
    afterCreated(){
      this.$router.push({path: '/leases'})
    }
  },
  created(){
    this.$store.dispatch("store/fetchStores")
  }
}
</script>

<style>

</style>